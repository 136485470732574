@import 'variables';
@import "mixins";

.isMobileDemoContainer {
  margin-top: -30px;
}

.gamePageHeading {
  width: 1290px;
  height: fit-content;
  margin: 0 auto;
  //border-top: 1px solid var(--theme-border1);
  padding: 24px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;



    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      margin: 0;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }

  &.for_demo {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    transform: translateY(30px);



    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;

    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }

  .general_inf {
    padding: 0 !important;
    //gap: 50px;
    //display: flex;
    margin-top: 50px;

    .img {
      position: relative;
      float: left;
      max-width: 200px;
      height: auto;
      margin-right: 14px;
    }

    .headings {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 0;

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 100%;
        margin: 0;
      }


      .vendor_link {
        width: fit-content;
        padding: 8px 12px;
        border-radius: 8px;
        //height: 60px;
        background: var(--theme-bg2-hover);
        border: 1px solid var(--theme-bg2-hover);
        transition: 0.2s all;
        -webkit-transition: 0.2s all;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        text-decoration: none;


        &.clickable {

          &:hover {
            border: 1px solid var(--theme-primary-yellow);
          }
        }

        img {
          width: 100%;
          height: auto;
        }

        @media (max-width: 745px) {
          font-size: 14px;
          padding: 4px 8px;
        }
      }
    }

    @media (max-width: 745px) {
      flex-direction: column;
      padding: 0 16px;
      align-items: center;
      text-align: center;
      gap: 18px;


      img {
        width: 120px;
      }

      .headings {
        gap: 8px;
        text-align: start;
        //align-items: center;

        h1 {
          font-size: 18px;
        }
      }
    }
  }

  .descr {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    line-height: 120%;
    //padding: 0 16px;
    margin-top: 16px;
    text-align: start;

    @media (max-width: 745px) {
      font-size: 12px !important;
    }
  }

  .table_container {
    //padding: 0 16px;

    table {
      border-collapse: collapse;
      width: 100%;
      margin-top: 30px;

    }

    th, td {
      padding: 14px 10px;
      text-align: start;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;



      @media (max-width: 700px) {
        font-size: 14px;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }


    tr:nth-child(even) {
      background-color: var(--theme-bg2-hover)
    }


    p {
      margin: 0 0 12px 0;
    }

    li p {
      margin: 0;
    }

  }

  @media (max-width: 400px) {
    padding: 16px;
  }
}

.demo_header {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 100px;
  margin-bottom: 20px;
  padding: 8px;
}

.casinoCard {
  width: 228px;
}

.provider_block {
  display: flex;
  justify-content: center;
  padding-left: 8px;
}

.filterPreloader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
  height: 56px;
  padding: 0 20px;
  align-items: center;
  opacity: 0;
}

.card {
  position: relative;
  transform: scale(1);
  -webkit-transform: scale(1);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  text-align: center;

  &__favButton {
    position: absolute;
    right: 12px;
    top: 12px;
    background: none;
    border: none;
    color: #fff;
    display: none;
    width: 32px;
    height: 32px;

    svg {
      width: 100%;
    }
  }

  &__home_slider_item {
    width: 220px;
    max-width: 100%;


    &:hover {
      .card__inner {
        opacity: 1;
      }

      .card__favorite {
        opacity: 1;
      }
    }
  }

  &__min_right {
    margin-right: 6px;
  }

  &__regular_right {
    margin-right: 14px;
  }

  &:hover img{
    filter: brightness(0.25);
      //transform: scale(1.02);
      //-webkit-transform: scale(1.02);

  }

  & img {
    display: block;
    width: 100%;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    max-width: 100%;
  }

  &__rtp {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 11px;
    font-size: 17px;
    font-weight: 500;

    > img {
      width: 12px;
      height: auto;
      display: block;
      margin-right: 8px;
      filter: none !important;
      -webkit-filter: none !important;
    }

    &__hot {
      //border: 1px solid red;
      width: 100%;
      background: rgba(241, 91, 27, 0.20);
      border-radius: 0 0 15px 15px;
    }

    &__cold {
      background: rgba(71, 89, 254, 0.20);
      border-radius: 0 0 15px 15px;

      > img {
        width: 16px;
      }
    }
  }

  &__about {
    top: -5px;
    position: absolute;
    background: rgba(0,0,0, 0.5);
    left: -5px;
    right: -5px;
    bottom:-5px;
    display: none;
    padding: 15px;
    //transition: transform 0.5s;
    //-webkit-transition: transform 0.5s;
  }

  &:hover{
    display: block;
    transform: scale(1.0,1);

    .card__favButton {
      display: block;
    }
  }

  &__playIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    background: $secondary-color;
  }

  &__btn {
    background: transparent;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #EEB665;
    margin-top: 10px;
    color: white;
  }

  &__title {
    color: #fff;
    pointer-events: none;
    user-select: none;
    margin: 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__subtitle {
    color: $primary-color;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 32px;
  }

  &__play {
    border-radius: 100px;
    background: #FBB90E;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.60) inset, 0px -4px 8px 0px rgba(0, 0, 0, 0.40) inset;
    color: $spins_gray_bg2;
    outline: none;
    border: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    height: 64px;
    width: 64px;
    margin-bottom: 6px;

    svg {
      width: 40px;
      height: 40px;
      transform: translateX(4px);
    }

    &:hover {
      box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.60) inset, 0px -1px 2px 0px rgba(0, 0, 0, 0.40) inset;
      color: #000;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
    }
  }

  &__play_demo {
    outline: none;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    padding: 9px 24px;
    border-radius: 30px;
    //background:  #2D3543;
    //box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.20);
    //transition: all .3s ease;
    //-webkit-transition: all .3s ease;
    text-transform: uppercase;
    background: none;
    border: none;
    color: white;
    cursor:  pointer;
    //
    //&:hover {
    //  //background:  #2D3543;
    //  //box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.60) inset, 0px -1px 2px 0px rgba(0, 0, 0, 0.40) inset;
    //  transition: all .3s ease;
    //  -webkit-transition: all .3s ease;
    //}
  }

  &__inner {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    max-width: 100%;
    width: 100%;
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 193px;

  }

  &__providerImage {
    max-height: 48px !important;
    max-width: 100% !important;
    width: auto !important;
    display: block;
    margin: 0 auto 16px;
    filter: none !important;
    -webkit-filter: none !important;
    transform: none !important;
    -webkit-transform: none !important;
  }

  &__favorite {
    position: absolute;
    z-index: 20;

    top: 10px;
    //right: 10px;
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;

    &__loading {
      cursor: wait;
    }
  }

  &__minimize {
    width: 190px;
    max-width: 100%;

    & .card__subtitle,
    & .card__title,
    & .card__providerImage {
      margin-bottom: 8px;
    }
  }

  &:hover {
    .card__inner {
      opacity: 1;
    }

    .card__favorite {
      opacity: 1;
    }
  }
}

.card__rtp__hot_data {
  overflow: hidden;
  color:  #FFF;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 8px;
}

.card__inner_text {
  color:  #15191E;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.providerName_card{
  border-radius: 8px;
  border: 1px solid  rgba(21, 25, 30, 0.70);
  background:  rgba(21, 25, 30, 0.70);
  color: white;
  padding: 3px 16px;
  width:fit-content;
  max-width: 90%;
  border: 1px solid #494949;
  cursor: pointer;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;

  &:hover {
    border: 1px solid  rgba(21, 25, 30, 1);
    background:  rgba(21, 25, 30, 1);
  }
}

.hideDetails {
    display: none;
  //opacity: 0;
  }

.hideDetailsInner {
  //margin-bottom: 45px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: 100%;
  justify-content: start;

  &__scroll {
    height: inherit;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    width: 14.2857142857%;
    flex: 0 0 14.2857142857%;

    //margin-bottom: 10px;
  }

  &__categ {
    @include pageContainerSidesPadding;
  }
}

.closeButton{
  @include closeBtnShadowed();
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
  @include fullWidthElementsPadding;

  h1 {
    margin: 0;
  }

  .filtersBtn {
    border: none;
    background: none;
    //color: $primary-color;
    display: none;
  }

  .overlayBlock {
    display: none;
  }

  .overlayFilters {
    display: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    position: relative;

    .providersContainer {
      position: absolute;
      z-index: 1;
      width: 373px;
      height: 686px;
      top: 67px;
      transform: translateX(-25%);
      background: #1B1025;
      box-shadow: 0px 20px 80px rgba(238, 182, 101, 0.1);
      border-radius: 12px;
      padding: 25px;
      overflow-y: scroll;
      transition: 0.3s all;
      -webkit-transition: 0.3s all;
      opacity: 0;


      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;


        .item {
          //background-color: #0E0C10;
          //border: 1px solid #0E0C10;
          max-width: 110px;
          width: 100%;

          height: 48px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          -webkit-align-items: center;
          cursor: pointer;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;

          border-radius: 8px;
          padding: 5px;

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
          }

          //&:hover {
          //  border-color: $dark_text;
          //}
          //
          //&.active {
          //  border-color: $primary-color;
          //}


        }
      }
    }

    .resetButton {
      max-height: 40px !important;
      background: #fbb90e !important;

      &:hover {
        background: #fdb806 !important;
      }
    }
  }


}

.page_title {
  font-size: 30px;
  //color: #fff;
  letter-spacing: .24px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  //line-height: 1;


  &__count {
    letter-spacing: .24px;
    text-transform: capitalize;
    font-size: 14px;
    color: #eeb665;
    font-weight: 500;
    padding-left: 10px;
  }
}

.window {
  display: inline-flex;
  //width: 100%;
  //max-width: 1306px;
  margin: 0 auto;
  background-color:transparent;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__game {
    flex: 1;
    position: relative;

    &__loader {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: flex;
      position: absolute;
      border: 3px solid var(--theme-bg1);
      background: var(--theme-var-bg-main);
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
    }

    iframe {
      height: 755px;
      width: 100%;
      display: block;
      position: relative;
      z-index: 10;
      border: none;
    }
  }

  &__bar {
    flex: 0 0 56px;
    width: 56px;
    max-width: 56px;
    overflow: hidden;
    position: absolute;
    right: -56px;
    top: 0;
    bottom: 0;

    &__inner {
      background-color: #19131D;
      border-radius:0  8px  8px 0;
      min-height: 50px;
      display: flex;
      width: 100%;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      padding: 16px 0;
    }
  }

  &__textualBtn {
    color: $primary-dark;
    background-color: transparent;
    border: 0;
    padding: 5px 10px;
    font-size: 14px;
    outline: none;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
  }

  &__btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    padding: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    color: #fff;
    margin: 0 auto;

    svg {
      max-width: 24px;
      max-height: 24px;
    }

    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      color: #8F84A4;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      padding: 0 5px;
      margin-bottom: 20px;
      display: none;
    }

    &:hover {
      //background-color: #27222D;
    }
  }

  &__fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    max-width: 100% !important;
    margin: 0 !important;

    .window__game {
      iframe {
        height: 100%;
        width: 100%;
      }
    }

    .window__bar {
      position: unset;
    }

    .window__bar__inner {
      background-color: transparent;
    }
  }

  &__playIcon {
    border: 1px solid $primary-dark;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;

    svg {
      max-width: 19px !important;
    }
  }

  &__depositIcon {
    //border: 1px solid $primary-dark;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    svg {
      max-width: 48% !important;
    }
  }

  &__textualBtn {
    .window__depositIcon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    //transition: all .3s ease;
    //-webkit-transition: all .3s ease;

}

.overlay.active {
  opacity: 1;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  pointer-events: initial;

}

.popup {
  display: flex;
  flex-direction: column;
  padding:0 0 8px 0;
  border-radius: 8px 8px 0px 0px;
  //transition: all .3s ease;
  //-webkit-transition: all .3s ease;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: 0 auto;
  max-width: 445px;
  //overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
    srollbar-width: none;
  }

  &.active {
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateY(0);
    -webkit-transform: translateY(0);

    .overlay{
      display: block;
    }

   body {
      overflow: hidden;
    }
  }

  &__fullWrapper {

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }

  }

  &__fullCategories {
    padding: 16px 16px 5px 16px;
    margin-top: 10px;

    //border-top: 1px solid #27222D;
    //border-bottom: 1px solid #27222D;
  }

  &__btnFavorite {
    padding: 0 16px 8px 16px;
    //border-top: 1px solid #27222D;
    //border-bottom: 1px solid grey;
  }

  &__recommendedGame__BlockTitle {
    padding-left: 16px;
    //margin-top: 8px;
    color: #BBB;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.1px;
    padding-top: 8px;
  }

  &__closeicon {
    position: sticky;
    top: -4px;
    z-index: 9999;
    text-align: right;
    right: 9px;
    margin: 10px 10px 0;

  }

  &__closeLine {
    width: 50px;
    height: 2px;
    background: #8F84A4;
    position: absolute;
    top: 50%;
    transform: translateX(50%);
    right: 50%;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin-top: 10px;
    position: relative;
  }
  &__gameName {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  &__btnSeeMore {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: capitalize !important;
    font-size: 14px;
    min-height: 56px;
  }


  &__fullBlock {
    display: flex;
    padding: 0 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .card__subtitle {
      font-size: 14px;
    }
  }

  &__fullBlockWrapper {
    justify-content: center;
  }

  &__gameNameBlock {
    flex: 1;
    width: 100%;
    padding-bottom: 24px;

  }

  &__providerImage {
    max-width: 140px;
    margin: auto;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  &__fullGameName {
    //flex:1;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__fullicon {
    flex: 0;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  &__imageWrapper {
    max-width: 195px;
    //width: 100%;
    margin-top: -22px;
    img{
      width: 100%;
    }
  }
  &__demoBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: transparent;
    margin-top: 24px;
    margin-bottom: 60px;
    border: none;
    color:  #EEB665;
    margin: auto;
    flex: 1;
    border-radius: 30px;
    height: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  &__iconFullBlock {
    margin-right: 10px;
  }
  &__wrapperPlatinum {
    display: flex;
    justify-content: space-between;
    //background: #27222D;
    padding: 11px 9px;
    border-radius: 8px;
    margin-bottom: 5px;
  }

  &__currency {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $spins-primary-color;

  }
  &__prize {
     margin-right: 5px;
  }

  &__demoBtnArrow {
    width: 10px;
    height: 16px;
    margin-left: 5px;
  }
  &__seeMoreBtn {
    position: relative;
    background: transparent;
    border: none;
    color:  #EEB665;

  }

  &__recommendedGame__block {
    display: flex;
  }

  &__recommendedGame {
    max-width: 100px;
    //height: 100%;

    img{
      width: 100%;
    }


  }

  &__seeMore {
    position: absolute;
    width: auto!important;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
  }

  &__seeMoreWrapper {
    //border-top: 1px solid #27222D;
    padding: 8px;
    padding-left: 16px;

  }

}

.thumbnailWrapper{
  margin-top: -10px;
}

.favorite_block {
  display: flex;
  align-items: center;
}

.bgPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 30px;
  height: 40px;
  margin-right: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.favoriteDemoBtnWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;

  button {
    min-height: 40px;
    text-transform: capitalize !important;

    display: flex;
    gap: 8px;
  }

  &.loggedIn {
    button {
      flex: 1;
      padding: 0 8px;
    }
  }
}

.window__game__loader {
  img {
    height: auto;
  }
}


@media(max-width: 1620px) {

  .header {
    .filters {
      display: none;
    }

    .overlayFilters {
      padding: 16px;
      width: 360px;
      height: 100%;
      z-index: 10000;
      position: fixed;
      display: block;
      top: 0;
      right: 0;
      transform: translateX(100%);
      background: #1B1025;
      box-shadow: 0px 20px 80px rgba(238, 182, 101, 0.1);
      transition: 0.3s all;
      -webkit-transition: 0.3s all;

      &.overlayFiltersActive {
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        transform: translateX(0);
      }

      .outlinedFilter {
        margin-top: 0 !important;
      }

      .scrollFilters {
        margin-top: 7px;

        .divider {
          background: #6c757d;
          width: 100%;
          height: 1px;
        }

        .providersFilterGrid {
          //border: 5px solid #ff0000;
          width: 100%;
          overflow-y: scroll;
          z-index: 1;
          top: 50px;
          left: -50%;
          //background-color: #1B1025;
          transform: translateY(10px);
          -webkit-transform: translateY(10px);
          opacity: 1;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
          margin-bottom: 30px;

          &::-webkit-scrollbar {
            display: none;
          }

          .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 10px;
            grid-row-gap: 10px;

            &.checkBoxesGrid {
              grid-template-columns: repeat(2, 1fr);
              width: 100%;
              grid-column-gap: 3px;
              //border: 1px solid #fff;
              padding-bottom: 5px;
              overflow: hidden;

              .checkItem {
                display: flex;
                flex-direction: row;
                align-items: start;
                width: fit-content;
                //border: 1px solid #fff;
                &.long {
                  grid-column: span 2;
                }

                .checkItem__label {
                  transform: translateY(8px);
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                }
              }
            }

            .item {
              //background-color: #0E0C10;
              //border: 1px solid #0E0C10;
              max-width: 110px;
              width: 100%;
              height: 48px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              -webkit-align-items: center;
              cursor: pointer;
              transition: all .3s ease;
              -webkit-transition: all .3s ease;
              border-radius: 8px;
              padding: 5px;

              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
              }

              &:hover {
                border-color: $spins-primary-color;
              }

              &.active {
                border-color: $primary-color;
              }


            }
          }
        }
      }

      .sort_filters {
        height: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px;

        &__item {
          padding: 4px 12px;
          border-radius: 10px;
          cursor: pointer;

          &.selected {
            background: var(--theme-active-color);
          }

          &:hover {
            background: var(--theme-active-color);
          }
        }
      }

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #fff;
        margin-bottom: 12px;

        h4 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          margin: 0 !important;
        }

        button {
          margin-bottom: 0;
        }
      }

      .resetButton {
        width: 100%;
        background: $danger !important;
        margin-top: 5px;
        font-size: 14px !important;
      }

      .outlinedSelector_root__dh_nz  {
        margin-top: 0 !important;
      }


    }
  }

  .filtersBtn {
    border: none;
    background: none;
    //color: $primary-color;
    display: none;

    &.pageCategs {
      border: none;
      background: none;
      //color: $primary-color;
      display: block;
      z-index: 100;
    }
  }
}

@media(max-width: 1290px) {
  .gamePageHeading {
    width: 100vw;
    .container {

      h1 {
        font-size: 32px;
        line-height: 32px;
      }

      span {
        font-size: 16px;
        line-height: 16px;

      }
    }

  }
}

@media (max-width: 1620px) {
  .filterPreloader {
    margin-bottom: 10px;
  }

  .providersFilterGrid {
    width: 100%;
    //background: #1B1025;
    border-radius: 7px;
    overflow-y: scroll;

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;


      &.checkBoxesGrid {
        grid-row-gap: 0 !important;

        .checkItem {
          //border: 1px solid #fff;
          display: flex;
          flex-direction: row;
          align-items: start;
          width: fit-content;
          //border: 1px solid #fff;
          &.long {
            grid-column: span 2;
          }

          .checkItem__label {
            transform: translateY(8px);
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .item {
        //background-color: #0E0C10;
        //border: 1px solid #0E0C10;
        max-width: 110px;
        width: 100%;

        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        cursor: pointer;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;

        border-radius: 8px;
        padding: 5px;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
        }

        &:hover {
          border-color: $dark_text;
        }

        &.active {
          border-color: $primary-color;
        }


      }
    }

  }

  .card {
    .card__favorite {
      //display: none;

    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: unset;


    .filtersBtn {
      border: none;
      background: none;
      //color: $primary-color;
      display: block;
      z-index: 70;
    }

    .overlayBlock {
      display: flex;
      flex-direction: column;
      align-items: end;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid #fff;
      z-index: 999;
      opacity: 0;
      transition: 0.3s all;
      -webkit-transition: 0.3s all;
      pointer-events: none;

      &.overlayBlockActive {
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        opacity: 1;
        pointer-events: initial;
      }
    }



    .filters {
      display: none;
    }
  }

}

@media (min-width: 1200px) {
  .window {
    max-width: calc(100% - 30px);
    width: 100%;
  }

  .window__bar {
    right: 0;
  }

  .window__mobileBtn {
    display: none!important;
  }

  .window__btn__mobileIcon {
    display: none;
  }

  .window__game {
    height: calc(100vh - (124px + 20px));
    width: calc(100% - 56px) !important;
    max-width: 100%;
    flex: 0 0 auto;
    margin: 0;
    position: relative;

    &.demo {
      height: calc(100vh - 200px);
    }

    iframe {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  .window__fullScreen .window__game {
    width: calc(100% - 56px);
    height: 100%;
    aspect-ratio: unset;
  }

  .window__widthAspectRation {
    width: 100%;
  }

  .window__widthAspectRation .window__game {
    min-height: 0;
    height: auto;
    width: 100%;
    max-width: calc(100% + 200px);
  }



}

@media (max-width: 1800px) {
  .grid__slots .grid__item {
    width: 20% !important;
    flex: 0 0 20% !important;
  }
}

@media (max-width: 1500px) {
  .grid__slots .grid__item {
    width: 25% !important;
    flex: 0 0 25% !important;
  }
}

@media (max-width: 1365px) {
  .grid__slots .grid__item {
    width: 33.3333333333% !important;
    flex: 0 0 33.3333333333% !important;
  }
}

@media(max-width: 1199px) {
  .card__inner {
    //opacity: 1;
    display: none;
    height: 100px;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: space-between;
  }
  .card__play {
    width: 45px;
    height: auto;
    margin-bottom: 5px;
    & svg {
      display: inline-block;
      max-width: 15px;
      //height: auto;
    }
  }
  .card__title {
    font-size: 13px;
    margin: 0 0 5px;
  }
 .grid__item {
   width: 20%;
   flex: 0 0 20%;
 }
  .card__subtitle {
    font-size: 10px;
    margin-bottom: 0!important;
  }
  .card__providerImage {
    max-height: 32px !important;
    margin-bottom: 0!important;
  }
  .card__play_demo {
    font-size: 11px;
    padding: 5px 16px;
  }
  .search_bar__reset {
    background: #9D0000;
  }

  .card:hover{
    .card__favorite{
      opacity: 0;
    }
    .card__favButton {
      display: none;
    }
  }
}

@media(max-width: 1365px) {
  .grid__item {
      width: 20%;
      flex: 0 0 20%;
  }
}

@media(max-width: 1199px) {
  .grid__item {
    width: 25%;
    flex: 0 0 25%;
  }

  .card__inner {
    display: none;
    height: 100px;
  }
  //.slots__sort {
  //  max-width: 275px;
  //}
}

@media(max-width: 991px) {
  .casinoCard {
    width: 100px;
  }
  .card__home_slider_item {
    width: 144px;
    max-width: 100%;
  }
  .card__minimize {
    width: 100px;
  }
  .card__regular_right {
    margin-right: 8px;
  }

  .grid__item {
    width: 25%;
    flex: 0 0 25%;
  }

  .card__rtp__cold, .card__rtp__hot {
    border-radius: 0 0 8px 8px;
  }

}

@media(max-width: 850px) {
  //.grid__item {
  //  width: 25%;
  //  flex: 0 0 25%;
  //}
}

@media (max-width: 767px) {

  .gamePageHeading {
    width: 100vw;

    .container {
      transform: none;
      margin-top: 5px;
      h1 {
        font-size: 20px;
        line-height: 20px;
      }

      span {
        font-size: 12px;
        line-height: 12px;
      }
    }

  }

  .card {
    //width: 50%;
  }

  .window {
    margin-bottom: 30px;
  }
  .card__rtp {
    //max-width: 100px;
    font-size: 16px;
    padding: 6px 9px;
  }
}

@media(max-width: 700px) {
  .grid__item {
    width: 33.33333%;
    flex: 0 0 33.33333%;
  }

}

@media(max-width: 575px) {

  .gamePageHeading {
    width: 100vw;
    .container {
      margin: 5px auto 0;
      width: 320px;

    }
  }

  //.grid__item {
  //  width: 50%;
  //  flex: 0 0 50%;
  //}

  .filterPreloader {
    margin-bottom: 3px;
  }

  .card__title {
    margin-bottom: 5px!important;
  }

  .popup__imageWrapper {
    max-width: 112px;
  }

  .card__home_slider_item {
    //width: 33.33333%;
    //flex: 0 0 33.33333%;
  }

  .popup {
    max-height: 442px;
    padding: 0;
  }

  .popup__fullWrapper {
    max-height: 90%
  }

  .page_title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .grid__item {

  }

  .popup__imageWrapper {
    max-width: 112px;
  }
}

@media(max-width: 470px){

  .bgPopup, .popup__demoBtn {
    width: 100%;
    height: 40px;
  }
  .bgPopup {
    padding: 7px;
    //margin-bottom: 15px;
    //margin-right: 0;
    .favoriteText {
      display: none;
    }
  }
  .popup__demoBtn{
    padding: 13px;
  }

  .favoriteDemoBtnWrap {
    button {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

@media(max-width: 360px) {
  .filterPreloader {
    margin-bottom: 0;
  }

  .overlayFilters {
    width: 100vw !important;
  }

  .header {
    h1 {
      font-size: 20px;
    }
  }
}

.isMobile {
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 !important;
  width: 100%;
  max-width: 100%;

  &.isMobileDemo {
    width: 100vw;
    height: calc(100vh - 20px);
    position: unset;
    scrollbar-width: none;
    padding-top: 0 !important;

    iframe {
      z-index: 100;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .window__bar {
      z-index: 100;
      min-height: 60px;
      height: 60px;
    }

  }

  .window__game {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .window__game iframe {
    height: 100%;
    position: relative;

    &.is_iphone {
      height: calc(100% - 1px) !important;
    }

  }

  .window__bar__inner {
    background-color: transparent;
    padding: 0;
    height: 100%;
    border-radius: 0;
  }

  .window__btn {
    color: $primary-dark;
  }
  .window__btn__text {
    display: none;
  }

  .window__bar {
    background-color: $background-color;
  }

  .window__screenSizeBtn {
    display: none;
  }

  .window__btn__desktopIcon {
    display: none;
  }

  .window__mobileBtn {
    display: flex !important;
  }

  .window__btn__mobileIcon {
    display: flex !important;
  }

  &.portrait {
    padding-top: 48px;
    justify-content: flex-start;

    .window__go_real_btn {
      display: none;
    }

    .window__bar {
      right: 0;
      left: 0;
      bottom: auto;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      height: 48px;

      &__inner {
        flex-direction: row;
        -webkit-flex-direction: row;
        width: 100%;
        height: 100%;
        background-color: #1A1A1A;
        justify-content: flex-start;
      }
    }

    .window__btn {
      height: 30px;
      width: 30px;
    }

    .window__btn__text {
      margin-bottom: 0;
      max-width: 60px;
    }

    .window__mobileBtn {
      margin-left: auto;
    }
  }

  &.landscape {
    padding-right: 48px;

    .window__go_real_text {
      display: none;
    }

    .window__bar__inner {
      padding: 2px 0 0;
    }

    .window__bar {
      right: 0;
      width: 48px;
    }

    .window__mobileBtn {
      margin-top: auto;
    }
  }
}